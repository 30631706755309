<script lang="ts">
	import { onMount } from 'svelte'
	import { MetaTags } from 'svelte-meta-tags'
	import { writable } from 'svelte/store'
	import { fly } from 'svelte/transition'
	import { cubicInOut } from 'svelte/easing'
	import { partytownSnippet } from '@builder.io/partytown/integration'
	import { ParaglideJS } from '@inlang/paraglide-sveltekit'
	import { createDialog, melt } from '@melt-ui/svelte'
	import { page } from '$app/stores'
	import { afterNavigate, beforeNavigate } from '$app/navigation'
	import { i18n } from '$lib/i18n'
	import { detectDevice, detectDeviceType, phg } from '$lib/utils/posthog'
	import * as m from '$lib/paraglide/messages'
	import GetListedDialog from '$lib/components/dialogs/GetListedDialog.svelte'
	import '../styles/app.css'
	import type { LayoutData } from './$types'

	export let data: LayoutData

	const mobileMenuOpen = writable(false)
	const getListedDialogOpen = writable(false)

	const {
		elements: { portalled, content },
		states: { open: isOpen },
	} = createDialog({
		open: mobileMenuOpen,
		closeOnOutsideClick: false,
	})

	onMount(() => {
		phg?.identify(data.userId, {
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})

		window.addEventListener('vite:preloadError', () => {
			window.location.reload()
		})

		console.info(
			`%cChainspect\n%cHi! Do you need our data? Contact us by email contact@chainspect.app or in Telegram @chainspect_app and we'll try to help you with the data you need!`,
			`
				font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				font-size: 32px;
				font-weight: 600;
				line-height: 1.5;
				background: linear-gradient(to right, rgb(2, 125, 253), rgb(27, 230, 171));
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			`,
			`
				font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				padding: 10px;
				font-size: 14px;
				line-height: 1.2;
				background: rgb(31, 41, 55);
				color: rgb(255, 255, 255);
			`
		)
	})

	afterNavigate(() => {
		phg?.capture('$pageview', {
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})
	})

	beforeNavigate(navigation => {
		phg?.capture('$pageleave', {
			$prev_pageview_pathname: navigation.from?.url.pathname,
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})
		mobileMenuOpen.set(false)
	})
</script>

<svelte:head>
	<script>
		// Forward the necessary functions to the web worker layer
		partytown = {
			forward: ['dataLayer.push', 'gtag'],
		}
	</script>

	<!-- eslint-disable-next-line svelte/no-at-html-tags @typescript-eslint/no-unused-expressions -->
	{@html '<script>' + partytownSnippet() + '</script>'}

	<script
		type="text/partytown"
		src="https://www.googletagmanager.com/gtag/js?id=G-7V8P7RN34Z"
	></script>
	<script type="text/partytown">
		window.dataLayer = window.dataLayer || []
		window.gtag = function () {
			dataLayer.push(arguments)
		}
		gtag('js', new Date())
		gtag('config', 'G-7V8P7RN34Z')
	</script>
</svelte:head>

<ParaglideJS {i18n}>
	<MetaTags
		title={$page.data.seoTags?.title ?? m.seo_base_title()}
		description={$page.data.seoTags?.description ?? m.seo_base_description()}
		canonical={$page.data.seoTags?.canonical ?? 'https://chainspect.app/'}
		openGraph={{
			url: $page.data.seoTags?.canonical ?? 'https://chainspect.app/',
			title: $page.data.seoTags?.title ?? m.seo_base_title(),
			description: $page.data.seoTags?.description ?? m.seo_base_description(),
			type: $page.data.seoTags?.type ?? 'website',
			images: [
				{
					url: $page.data.seoTags?.image ?? 'https://chainspect.app/og',
					width: 1200,
					height: 630,
				},
			],
			siteName: 'Chainspect',
			article: $page.data.seoTags?.article
				? {
						...$page.data.seoTags.article,
						authors: ['https://twitter.com/chainspect_app'],
					}
				: {},
		}}
		twitter={{
			handle: '@chainspect_app',
			site: '@chainspect_app',
			cardType: 'summary_large_image',
			title: $page.data.seoTags?.title ?? m.seo_base_title(),
			description: $page.data.seoTags?.description ?? m.seo_base_description(),
			image: $page.data.seoTags?.image ?? 'https://chainspect.app/og',
		}}
	/>

	<header
		class="relative top-0 z-4 h-[72px] w-full border-b border-b-base-light bg-base-default backdrop-blur-sm mg:border-b-0 mg:border-none"
		class:sticky={$page.url.pathname === '/'}
		class:bg-opacity-90={!$mobileMenuOpen}
	>
		<nav
			class="flex h-full items-center justify-between px-3 py-2 xl:container"
			aria-label={m.mobile_menu_name()}
		>
			<a
				href="/"
				class="link-branding-gradient w-fit rounded py-2 text-xl font-bold focus-visible:ring xs:p-1 xs:text-2xl"
			>
				{m.name()}
			</a>

			<button
				type="button"
				class="ml-auto flex size-10 items-center justify-center rounded-lg p-1 text-base-content-light hover:bg-base-light focus-visible:bg-base-light focus-visible:ring mg:hidden"
				on:click={() => {
					mobileMenuOpen.update(prevOpen => !prevOpen)
				}}
				aria-label={$mobileMenuOpen ? m.mobile_menu_close() : m.mobile_menu_open()}
			>
				{#if $mobileMenuOpen}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="28"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<path d="M18 6 6 18" />
						<path d="m6 6 12 12" />
					</svg>
				{:else}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="28"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<line x1="4" x2="20" y1="12" y2="12" />
						<line x1="4" x2="20" y1="6" y2="6" />
						<line x1="4" x2="20" y1="18" y2="18" />
					</svg>
				{/if}
			</button>

			<div class="hidden h-full items-center gap-5 mg:flex">
				<ul class="flex items-center gap-4">
					<li>
						<a
							href="https://twitter.com/chainspect_app"
							target="_blank"
							rel="noopener"
							class="flex items-center justify-center rounded text-base-content-light transition-colors hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							aria-label={m.socials_twitter()}
							on:click={() => {
								phg?.capture('twitter_link_click', {
									place: 'header_icon',
								})
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path
									d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
								/>
							</svg>
						</a>
					</li>

					<li>
						<a
							href="https://discord.gg/nnmJf56XYU"
							target="_blank"
							rel="noopener"
							class="flex items-center justify-center rounded text-base-content-light transition-colors hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							aria-label={m.socials_discord()}
							on:click={() => {
								phg?.capture('discord_link_click', {
									place: 'header_icon',
								})
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="25"
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path
									d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"
								/>
							</svg>
						</a>
					</li>
				</ul>

				<span class="hidden h-6 w-px bg-base-lightest md:block" />

				<ul class="hidden gap-6 mg:flex">
					<li>
						<a
							href="/dashboard"
							class="rounded bg-transparent text-center text-base hover:text-base-content focus-visible:text-base-content focus-visible:ring {$page
								.url.pathname === '/dashboard'
								? 'text-base-content'
								: 'text-base-content-light'}"
							on:click={() => {
								if ($page.url.pathname === '/') {
									phg?.capture('open_dashboard', {
										place: 'header_link',
									})
								}
							}}
						>
							{m.link_dashboard()}
						</a>
					</li>

					<li>
						<a
							href="/compare"
							class="rounded bg-transparent text-center text-base hover:text-base-content focus-visible:text-base-content focus-visible:ring {$page
								.url.pathname === '/compare'
								? 'text-base-content'
								: 'text-base-content-light'}"
						>
							{m.link_compare()}
						</a>
					</li>

					<li>
						<a
							href="/blog"
							class="rounded bg-transparent text-center text-base hover:text-base-content focus-visible:text-base-content focus-visible:ring {$page
								.url.pathname === '/blog'
								? 'text-base-content'
								: 'text-base-content-light'}"
						>
							{m.link_blog()}
						</a>
					</li>

					<li>
						<a
							href="/about"
							class="rounded bg-transparent text-center text-base hover:text-base-content focus-visible:text-base-content focus-visible:ring {$page
								.url.pathname === '/about'
								? 'text-base-content'
								: 'text-base-content-light'}"
						>
							{m.link_about()}
						</a>
					</li>
				</ul>
			</div>

			{#if $page.url.pathname === '/'}
				<div class="hidden mg:ml-2 mg:block">
					<a
						href="/dashboard"
						class="button button-sm button-outline"
						on:click={() => {
							phg?.capture('open_dashboard', {
								place: 'header_button',
							})
						}}
					>
						{m.header_cta()}
					</a>
				</div>
			{/if}
		</nav>
	</header>

	<div use:melt={$portalled}>
		{#if $isOpen}
			<div
				use:melt={$content}
				class="fixed bottom-0 left-0 right-0 top-[72px] z-3 h-[calc(100svh-72px)] bg-base-default mg:hidden"
				transition:fly={{ duration: 250, y: -350, easing: cubicInOut }}
			>
				<nav class="flex h-full flex-col gap-4 p-2" aria-label={m.mobile_menu_name()}>
					<ul class="flex flex-col gap-3">
						<li>
							<a
								href="/dashboard"
								class="inline-flex w-full items-center justify-start gap-3 p-3 text-lg hover:text-base-content focus-visible:text-base-content {$page
									.url.pathname === '/dashboard'
									? 'text-base-content'
									: 'text-base-content/75'}"
								on:click={() => {
									if ($page.url.pathname === '/') {
										phg?.capture('open_dashboard', {
											place: 'mobile_menu_link',
										})
									}
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
									<circle cx="12" cy="12" r="2" />
									<path d="M13.4 10.6 19 5" />
								</svg>

								{m.link_dashboard()}
							</a>
						</li>

						<li>
							<a
								href="/compare"
								class="inline-flex w-full items-center justify-start gap-3 p-3 text-lg hover:text-base-content focus-visible:text-base-content {$page
									.url.pathname === '/compare'
									? 'text-base-content'
									: 'text-base-content/75'}"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path d="M8 3 4 7l4 4" />
									<path d="M4 7h16" />
									<path d="m16 21 4-4-4-4" />
									<path d="M20 17H4" />
								</svg>

								{m.link_compare()}
							</a>
						</li>

						<li>
							<a
								href="/blog"
								class="inline-flex w-full items-center justify-start gap-3 p-3 text-lg hover:text-base-content focus-visible:text-base-content {$page
									.url.pathname === '/blog'
									? 'text-base-content'
									: 'text-base-content/75'}"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2"
									/>
									<path d="M18 14h-8" />
									<path d="M15 18h-5" />
									<path d="M10 6h8v4h-8V6Z" />
								</svg>

								{m.link_blog()}
							</a>
						</li>

						<li>
							<a
								href="/about"
								class="inline-flex w-full items-center justify-start gap-3 p-3 text-lg hover:text-base-content focus-visible:text-base-content {$page
									.url.pathname === '/about'
									? 'text-base-content'
									: 'text-base-content/75'}"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"
									/>
									<line x1="12" x2="12" y1="16" y2="12" />
									<line x1="12" x2="12.01" y1="8" y2="8" />
								</svg>

								{m.link_about()}
							</a>
						</li>
					</ul>

					<ul class="mb-4 mt-auto flex items-center justify-center gap-6">
						<li>
							<a
								href="https://twitter.com/chainspect_app"
								target="_blank"
								rel="noopener"
								class="flex items-center justify-center rounded text-base-content-light transition-colors hover:text-base-content focus-visible:text-base-content focus-visible:ring"
								aria-label={m.socials_twitter()}
								on:click={() => {
									phg?.capture('twitter_link_click', {
										place: 'mobile_menu_icon',
									})
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="28"
									height="28"
									viewBox="0 0 24 24"
									fill="currentColor"
								>
									<path
										d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
									/>
								</svg>
							</a>
						</li>

						<li>
							<a
								href="https://discord.gg/nnmJf56XYU"
								target="_blank"
								rel="noopener"
								class="flex items-center justify-center rounded text-base-content-light transition-colors hover:text-base-content focus-visible:text-base-content focus-visible:ring"
								aria-label={m.socials_discord()}
								on:click={() => {
									phg?.capture('discord_link_click', {
										place: 'mobile_menu_icon',
									})
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="32"
									height="32"
									viewBox="0 0 24 24"
									fill="currentColor"
								>
									<path
										d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"
									/>
								</svg>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		{/if}
	</div>

	<slot />

	<footer
		class="mt-auto flex items-center justify-between gap-3 border-t border-t-base-light py-6 sm:border-t-0 sm:py-10"
	>
		<nav class="flex w-full flex-col gap-10 px-3 xl:container">
			<div class="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center">
				<a
					href="/"
					class="link-branding-gradient w-fit rounded text-xl font-bold focus-visible:ring xs:p-1 xs:text-2xl"
				>
					{m.name()}
				</a>

				<button
					class="button button-sm button-outline whitespace-nowrap"
					on:click={() => {
						getListedDialogOpen.set(true)

						phg?.capture('get_listed_dialog_open', {
							place: 'footer',
						})
					}}
				>
					{m.get_listed_cta()}
				</button>
			</div>

			<ul
				class="grid grid-cols-2 gap-x-1 gap-y-10 px-1 text-sm xs:text-base md:grid-cols-3 md:gap-10"
			>
				<li>
					<p class="font-semibold">
						{m.footer_resources()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="/dashboard"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.link_dashboard()}
							</a>
						</li>

						<li>
							<a
								href="/compare"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.link_compare()}
							</a>
						</li>

						<li>
							<a
								href="/blog"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.link_blog()}
							</a>
						</li>

						<li>
							<a
								href="/about"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.link_about()}
							</a>
						</li>
					</ul>
				</li>

				<li>
					<p class="font-semibold">
						{m.footer_community()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="https://twitter.com/chainspect_app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
								on:click={() => {
									phg?.capture('twitter_link_click', {
										place: 'footer_text',
									})
								}}
							>
								{m.socials_twitter()}
							</a>
						</li>

						<li>
							<a
								href="https://discord.gg/nnmJf56XYU"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
								on:click={() => {
									phg?.capture('discord_link_click', {
										place: 'footer_text',
									})
								}}
							>
								{m.socials_discord()}
							</a>
						</li>
					</ul>
				</li>

				<li>
					<p class="font-semibold">
						{m.footer_contacts()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="https://t.me/chainspect_app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.socials_telegram()}
							</a>
						</li>

						<li>
							<a
								href="mailto:contact@chainspect.app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-base-content-light hover:text-base-content focus-visible:text-base-content focus-visible:ring"
							>
								{m.social_email()}
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	</footer>

	<GetListedDialog open={getListedDialogOpen} />
</ParaglideJS>
